import { render, staticRenderFns } from "./top_card.vue?vue&type=template&id=6c0fb203&scoped=true&"
import script from "./top_card.vue?vue&type=script&lang=js&"
export * from "./top_card.vue?vue&type=script&lang=js&"
import style0 from "./top_card.vue?vue&type=style&index=0&id=6c0fb203&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0fb203",
  null
  
)

export default component.exports